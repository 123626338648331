const helperEvent = {
    trigger: function (eventName, element, data = null, bubbles = true, cancelable = true) {
        let event = document.createEvent("CustomEvent");
        event.initCustomEvent(eventName, bubbles, cancelable, data);
        element.dispatchEvent(event);
    },
    listen: function (eventName, element, callback) {
        element.addEventListener(eventName, callback);
    },
    remove: function (eventName, element, callback) {
        element.removeEventListener(eventName, callback);
    }
};


class Animation {
    $element;
    animationName;
    framesPerSecond = 60;
    msInSecond = 1000;
    millisecondsPerFrame = this.framesPerSecond / this.msInSecond;
    animationToLeave = true;
    animationFinished = true;
    frameFirst = true;
    longestAnimationName = "";
    static transitionEventEndName = "";
    static eventEndPossibility = {
        WebkitTransition: "webkitTransitionEnd",
        MozTransition: "transitionend",
        MSTransition: "msTransitionEnd",
        OTransition: "oTransitionEnd",
        transition: "transitionEnd",
    };

    static event = {
        start: "animationStart",
        end: "animationEnd",
        afterEnd: "animationAfterEnd",
        addFrame: {
            first: "animationAddFirstFrame",
            last: "animationAddLastFrame"
        },
        removeFrame: {
            first: "animationRemoveFirstFrame",
            last: "animationRemoveLastFrame"
        }
    };

    animationClass = {
        enter: {
            start: "",
            active: "",
            end: ""
        },
        leave: {
            start: "",
            active: "",
            end: ""
        }
    };

    constructor ($element, animationName) {
        this.$element = $element;
        this.animationName = animationName;

        let keys = Object.keys(Animation.eventEndPossibility);
        for (let index = 0; index < keys.length; index++) {
            let eventKey = keys[index];
            if (eventKey in this.$element.style) {
                Animation.transitionEventEndName = Animation.eventEndPossibility[eventKey];
                break;
            }
        }

        this.animationClass.enter = {
            start: `a_${animationName}--enter`,
            active: `a_${animationName}--enterActive`,
            end: `a_${animationName}--enterTo`
        };
        this.animationClass.leave = {
            start: `a_${animationName}--leave`,
            active: `a_${animationName}--leaveActive`,
            end: `a_${animationName}--leaveTo`,
        };
    }

    switchAnimationToLeave() {
        this.animationToLeave = !this.animationToLeave;
    }
    triggerEvent(eventName){
        helperEvent.trigger(eventName, this.$element, { initialState: this.animationToLeave }, false);
    }
    onTransitionEnd = (event) => {
        if (event.propertyName === this.longestAnimationName) {
            let $element = event.target;
            if ($element) {
                helperEvent.remove(Animation.transitionEventEndName, $element, this.onTransitionEnd);
            }
            this.animationFinished = true;
            this.handleAnimation();
        }
    }
    setLongestAnimationName() {
        let computedStyle = window.getComputedStyle(this.$element);
        let allTransitionDurations = computedStyle.transitionDuration.split(",");
        let allTransitionProperties = computedStyle.transitionProperty.split(",");

        let longestDuration = 0;
        for (let index = 0; index < allTransitionDurations.length; index++) {
            let transitionDuration = parseFloat(allTransitionDurations[index].trim());
            if (longestDuration < transitionDuration) {
                this.longestAnimationName = allTransitionProperties[index];
            }
        }
    }
    runAnimation() {
        if (Animation.transitionEventEndName && this.animationFinished) {
            helperEvent.listen(Animation.transitionEventEndName, this.$element, this.onTransitionEnd);
        }

        if (!this.animationFinished) {
            this.triggerEvent(Animation.event.end);
        } else {
            this.frameFirst = true;
            this.animationFinished = false;
        }

        this.triggerEvent(Animation.event.start);
        this.switchAnimationToLeave();

        this.handleAnimation();
    }

    handleAnimationTo() {

        let classList = this.$element.classList;
        let classToAdd = this.animationClass.enter;
        let classToRemove = this.animationClass.leave;
        if (this.animationToLeave) {
            classToAdd = this.animationClass.leave;
            classToRemove = this.animationClass.enter;
        }
        classList.remove(classToRemove.start);
        classList.remove(classToRemove.active);
        classList.remove(classToRemove.end);

        if (!classList.contains(classToAdd.active) && !this.animationFinished) {
            classList.add(classToAdd.active);
        }


        if (this.frameFirst && !classList.contains(classToAdd.start) && !this.animationFinished) {
            classList.add(classToAdd.start);
            this.frameFirst = false;
        } else if (classList.contains(classToAdd.start)) {
            classList.remove(classToAdd.start);
        }

        if (!classList.contains(classToAdd.end) && this.animationFinished) {
            classList.add(classToAdd.end);
        } else if (classList.contains(classToAdd.end)) {
            classList.remove(classToAdd.end);
            classList.remove(classToAdd.active);
        }

        if (!this.longestAnimationName) {
            this.setLongestAnimationName();
        }
    }

    handleAnimation() {

        this.handleAnimationTo();

        window.setTimeout(() => {
            if (this.animationFinished) {
                this.triggerEvent(Animation.event.end);
                this.triggerEvent(Animation.event.afterEnd);
            }
            this.handleAnimationTo();
        }, this.millisecondsPerFrame);
    }
}



const helperAnimation = {
    slideDownUp: {
        enter: function (element) {
            element.style.position = "absolute";
            element.style.visibility = "hidden";
            element.style.height = "auto";

            let height = window.getComputedStyle(element).height;

            element.style.width = "";
            element.style.position = "";
            element.style.visibility = "";
            element.style.height = 0;

            // Force repaint to make sure the
            // animation is triggered correctly.

            window.getComputedStyle(element).height;

            window.setTimeout(() => {
                element.style.height = height;
            });
        },
        afterEnter: function (element) {
            element.style.height = "";
        },
        leave: function (element) {
            element.style.height = window.getComputedStyle(element).height;

            window.getComputedStyle(element).height;

            window.setTimeout(() => {
                element.style.height = 0;
            });
        },
        afterLeave: function (element) {
        }
    }
};
if (document.querySelector(".js_slideDownUp")) {

    let animationSlideDownUp = {
        animations: [],
        openedState: "f_set--opened",
        animationName: "slideDownUp",
        getAnimateSlideContent: function ($trigger) {
            let $triggerParent = $trigger.parentElement;
            let $slideContent = $triggerParent.querySelector(`.js_${animationSlideDownUp.animationName}-content`);

            let animationContentSelector = $trigger.dataset.animationContentSelector;
            if (animationContentSelector) {
                try {
                    $slideContent = document.querySelector(animationContentSelector);
                } catch (e) {}
            }
            return $slideContent;
        },
        animateSlide: function ($trigger) {
            let $slideContent = this.getAnimateSlideContent($trigger);

            let addOpenedStatusToParent = $trigger.dataset.animationOpenedStatusToParent;

            if ($slideContent) {

                helperEvent.remove(Animation.event.start, $slideContent, animationSlideDownUp.animationStart);
                helperEvent.remove(Animation.event.end, $slideContent, animationSlideDownUp.animationEnd);
                helperEvent.remove(Animation.event.afterEnd, $slideContent, animationSlideDownUp.animationAfterEnd);

                let animationId = $slideContent.dataset.animationSlideDownUpId;
                if (!animationId) {
                    animationId = `id${this.animations.length}`;
                    $slideContent.dataset.animationSlideDownUpId = animationId;
                    this.animations.push({
                        id: animationId,
                        $trigger: $trigger,
                        Animation: null
                    });
                }

                let animationData = null;
                for (let animation of this.animations) {
                    if (animation.id === animationId) {
                        animationData = animation;
                        break;
                    }
                }

                if (animationData) {

                    if (animationData && !animationData.Animation) {
                        animationData.Animation = new Animation($slideContent, animationSlideDownUp.animationName);
                        if ($slideContent.classList.contains(this.openedState)) {
                            animationData.Animation.switchAnimationToLeave();
                        }
                    }

                    let openedStatus = this.openedState;

                    let parentOpenedStatus = "";
                    if (addOpenedStatusToParent) {
                        parentOpenedStatus = addOpenedStatusToParent;
                        let $triggerParent = $trigger.parentElement;
                        if (!$triggerParent.classList.contains(parentOpenedStatus)) {
                            $triggerParent.classList.add(parentOpenedStatus);
                        } else {
                            $triggerParent.classList.remove(parentOpenedStatus);
                        }
                    }

                    if (!$slideContent.classList.contains(openedStatus)) {
                        $slideContent.classList.add(openedStatus);
                    } else {
                        $slideContent.classList.remove(openedStatus);
                    }

                    helperEvent.listen(Animation.event.start, $slideContent, animationSlideDownUp.animationStart);
                    helperEvent.listen(Animation.event.end, $slideContent, animationSlideDownUp.animationEnd);
                    helperEvent.listen(Animation.event.afterEnd, $slideContent, animationSlideDownUp.animationAfterEnd);

                    animationData.Animation.runAnimation();
                }
            }
        },
        animationStart: function (event) {
            let $element = event.target;
            $element.classList.remove(animationSlideDownUp.openedState);

            $element.style.display = "";
            if (event.detail.initialState) {
                helperAnimation.slideDownUp.enter($element);
            } else {
                helperAnimation.slideDownUp.leave($element);
            }
        },
        animationEnd: function (event) {
            let $element = event.target;
            if (!event.detail.initialState) {
                $element.classList.add(animationSlideDownUp.openedState);
            }
        },
        animationAfterEnd: function (event) {
            let $element = event.target;

            if (event.detail.initialState) {
                $element.style.display = "none";
            }
            helperAnimation.slideDownUp.afterEnter($element);
        }
    }


    document.addEventListener("DOMContentLoaded", function() {
        let $slideTriggers = document.querySelectorAll(`.js_${animationSlideDownUp.animationName}`);
        if ($slideTriggers) {
            for (let $slideTrigger of $slideTriggers) {
                let $slideContent = animationSlideDownUp.getAnimateSlideContent($slideTrigger);
                $slideContent.classList.add(`a_${animationSlideDownUp.animationName}`);
                helperEvent.listen("click", $slideTrigger, (event) => {
                    event.preventDefault();
                    animationSlideDownUp.animateSlide($slideTrigger);
                });
            }
        }
    });
    document.onload = function(){

    };


}
